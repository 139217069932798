export const socials = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/andrei-kusakin/",
  },
  {
    name: "github",
    link: "https://github.com/andreikusakin",
  },
  {
    name: "x/twitter",
    link: "https://x.com/AndrewKusakin",
  },
];
